/**
 * Finance Client store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import filterModel from './filter';

import PageEntity from '@/lib/layouts/page/pageEntity';
import { getDownloadLink, getFinanceClientList } from '@/api/finance';
import { getClients } from '@/api/client';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { prepareList } from '@/lib/FinanceClient';
import moment from 'moment';

import FinanceCustomerFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { destructuringDateString } from '@/lib/Utils';
import { Checkbox } from '@/interfaces/filter.interface';
import { DatePickerEntityList } from '@/lib/layouts/page/filterDatePicker.interface';

export const MODULE_NAME = 'financeCustomer';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class FinanceCustomerModule extends PageBaseModule {
  defaultSelectValue = {
    id: '0',
    value: 'Выберите',
  };
  filter: Filter;
  downloadModalParams: { isShow: boolean; title: string };
  dataLoaded = false;

  constructor(module: FinanceCustomerModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('Расчёт с клиентом');

    page.values.actionPagination = 'financeCustomer/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(FinanceCustomerFilter);
    this.filter.setFilterName('financeCustomerFilter');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);

    this.downloadModalParams = {
      isShow: false,
      title: 'Выгрузка данных',
    };
  }

  @Mutation
  SET_DATA_LOADED(value: boolean) {
    this.dataLoaded = value;
  }

  @Action({ rawError: true })
  async init() {
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.initList();
  }

  @Action({ rawError: true })
  async initList() {
    await this.filter.init();

    if (!this.filter.filterSettings.filter) {
      await this.filter.prepareFilterFromModal();
    }

    await this.getList();
  }

  @Action({ rawError: true })
  initDateRange() {
    let dateCurrent = moment();

    let startDay = '01';
    let endDay = '15';

    const currentDay = dateCurrent.date();
    if (currentDay <= 15) {
      dateCurrent = dateCurrent.subtract(1, 'months');

      startDay = '16';
      endDay = dateCurrent.endOf('month').date().toString(); // last day of moth
    }

    const month = dateCurrent.format('MM');
    const year = dateCurrent.format('YY');

    return {
      startDate: { day: startDay, month, year },
      endDate: { day: endDay, month, year },
    };
  }

  @Action({ rawError: true })
  async getList() {
    this.context.commit('SET_DATA_LOADED', false);

    try {
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getFinanceClientList(
        this.pageSettings.pageCurrent,
        itemsQuery,
        this.filter.filterSettings.filter
      );
      await this.setList(result);

      this.context.commit('SET_DATA_LOADED', true);
    } catch (error) {
      this.context.commit('setGlobalError', false);
    }
  }

  @Action({ rawError: true })
  async setList(table: TableApiInterface) {
    this.context.commit('SET_TABLE', prepareList(table));
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.getList();
  }

  @Action({ rawError: true })
  async initClientsFilter() {
    try {
      const result: { clients: { id: string; name: string }[] } = await getClients();

      return Object.values(result.clients).map((client: { id: string; name: string }) => {
        return {
          id: client.id,
          name: client.name,
          checked: false,
        };
      });
    } catch (error) {
      return [];
    }
  }

  @Mutation
  SET_IS_SHOW_DOWNLOAD_MODAL(bool: boolean) {
    this.downloadModalParams.isShow = bool;
  }

  @Action({ rawError: true })
  async showDownloadModal() {
    await this.context.commit('SET_IS_SHOW_DOWNLOAD_MODAL', true);
  }

  @Action({ rawError: true })
  async hideDownloadModal() {
    await this.context.commit('SET_IS_SHOW_DOWNLOAD_MODAL', false);
  }

  @Action({ rawError: true })
  async initDownload() {
    try {
      const endDate = destructuringDateString(
        (this.filter.filterSettings.filterModel.date.list as DatePickerEntityList).endDate.value,
        '/'
      );
      const startDate = destructuringDateString(
        (this.filter.filterSettings.filterModel.date.list as DatePickerEntityList).startDate.value,
        '/'
      );

      const result = await getDownloadLink(
        '20' + startDate.year + '-' + startDate.month + '-' + startDate.day,
        '20' + endDate.year + '-' + endDate.month + '-' + endDate.day,
        (this.filter.filterSettings.filterModel.clients?.list as Checkbox[])
          .filter((el: Checkbox) => el.checked)
          .map((el: Checkbox) => el.id)
      );

      const downloadLink = document.createElement('a');
      downloadLink.href = result;
      downloadLink.target = '_blank';
      downloadLink.style.display = 'none';
      document.body.append(downloadLink);
      downloadLink.click();
      setTimeout(() => {
        downloadLink.remove();
      });
    } catch (error) {
      this.context.commit('setGlobalError', false);
    }
  }
}

export default getModule(FinanceCustomerModule);
