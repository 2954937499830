import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils.ts';

/**
 * Returns processed finance client info for table
 *
 * @param {Object} table shops data
 * @param {Object} state component's this.pageSettings
 * @returns {Object} tableInfo
 */
export function prepareList(table: TableApiInterface) {
  const uiTable = new UITable(table as any);

  return uiTable
    .removeColumn('isTestClient')

    .setProperty('total_hours', 'align', 'right')
    .setProperty('total_checkup', 'align', 'right')
    .setProperty('total_payment', 'align', 'right')
    .setProperty('employee_payment', 'align', 'right')

    .getTable() as any;
}
