import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class FinanceCustomerFilter {
  filterHandlers = {
    update: 'financeCustomerFilter/updateFilter',
    reset: 'financeCustomerFilter/resetFilter',
    resetState: 'financeCustomerFilter/resetFilterState',
    removeSelected: 'financeCustomerFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createDateIntervalPickerEntity('date');
    filter.setName('date', 'picker_date');
    filter.setTitle('date', '');
    filter.setInitAction('date', 'financeCustomer/initDateRange');
    filter.setDateIntervalList('date', {
      startDate: {
        key: 'periodStart',
        type: 'date-picker',
        title: '',
        placeholder: '',
        value: '',
        action: 'financeCustomerFilter/updateStartDate',
      },
      endDate: {
        key: 'periodEnd',
        type: 'date-picker',
        title: '',
        placeholder: '',
        value: '',
        action: 'financeCustomerFilter/updateEndDate',
      },
    });

    filter.createMultiSelectEntity('clients');
    filter.setTitle('clients', 'Клиент');
    filter.setName('clients', 'multi_select_clients');
    filter.setInitAction('clients', 'financeCustomer/initClientsFilter');
    filter.setAction('clients', 'financeCustomerFilter/updateMultiSelect');
    filter.setSearchEnabled('clients', true);

    filter.createCheckboxEntity('isNotTest');
    filter.setName('isNotTest', 'checkbox_isNotTest');
    filter.setPreviewName('isNotTest', 'Без тестовых');
    filter.setIgnoreNull('isNotTest', true);
    filter.setListElement('isNotTest', {
      id: 'isNotTest',
      name: 'Без тестовых',
      checked: false,
    });
    filter.setAction('isNotTest', 'financeCustomerFilter/updateCheckbox');
    filter.setIconClassName('isNotTest', 'icon-is-active');

    this.filterModel = filter.filterModel;
  }
}
