<template>
  <div>
    <div class="cp-shift-form">
      <div class="cp-form__row cp-shift-common-info cp-padding__lsx">
        <div v-if="clientName.length" class="cp-form__row cp-shift-store-info__wrapper cp-shift-info-icon__wrapper">
          <span class="icon-clients" style="font-size: 20px"></span>
          <span class="cp-shift-common-info__link">{{ clientName }}</span>
        </div>
        <div class="cp-form__row cp-shift-store-info__wrapper cp-shift-info-icon__wrapper">
          <span class="icon-calendar-alt" style="font-size: 20px"></span>
          <span class="cp-shift-common-info__link">{{ dateString }}</span>
        </div>
      </div>
    </div>

    <div class="mr_modal-btn__wrapper">
      <div class="mr_modal-btn__bg mr_modal-btn__end mr_modal-btn__end">
        <form-button :text="'Выгрузить'" :type="'submit'" :outline="true" @btn-click="download" />
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from '@/lib/decorator';
import FormButton from '@/components/form/Button/index.vue';

@Component({
  components: {
    FormButton,
  },
  emits: ['downloadClicked'],
})
export default class DownloadModalContent extends Vue {
  @Prop({ default: '' }) clientName;
  @Prop() dateString;

  async download() {
    this.$emit('downloadClicked');
  }
}
</script>
