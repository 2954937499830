
import { Component, Vue } from '@/lib/decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import FilterInstant from '@/components/FilterInstant.vue';

import FinanceModule from '@/store/finance/index';
import FinanceCustomerModule from '@/store/finance/customer';
import AppTable from '@/components/ui/table/Table.vue';
import TextMoney from '@/components/table-items/TextMoney.vue';
import TextNumber from '@/components/table-items/TextNumber.vue';

import ModalBlock from '@/components/Modal.vue';
import DownloadModalContent from './_downloadModalContent.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import IconFont from '@/components/icons/IconFont.vue';
import { Checkbox } from '@/interfaces/filter.interface';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { FilterSettings } from '@/lib/layouts/page/filter.interface';
import { minutesToTime } from '@/lib/Utils';
import TimeFormatter from '@/components/TimeFormatter.vue';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    TimeFormatter,
    AppCol,
    AppRow,
    TextNumber,
    TextMoney,
    AppTable,
    TabsNav,
    FilterInstant,
    ModalBlock,
    DownloadModalContent,
    IconFont,
  },
})
export default class FinanceCustomerMain extends Vue {
  modalClientName = '';
  modalDateString = '';

  get dataLoaded(): boolean {
    return FinanceCustomerModule.dataLoaded;
  }

  get tabsNav(): TabsNavInterface {
    return FinanceModule.tabsNav;
  }

  get currentTab(): string {
    return FinanceModule.allTabs.finance_customer?.id;
  }

  get settings(): PageInterface {
    return FinanceCustomerModule.pageSettings;
  }

  get filterSettings(): FilterSettings {
    return FinanceCustomerModule.filter.filterSettings;
  }

  get downloadModalParams(): { isShow: boolean; title: string } {
    return FinanceCustomerModule.downloadModalParams;
  }

  get selectedStartDate(): string {
    return (FinanceCustomerModule.filter.filterSettings.filterModel.date.list as any)?.startDate.value;
  }

  get selectedEndDate(): string {
    return (FinanceCustomerModule.filter.filterSettings.filterModel.date.list as any)?.endDate.value;
  }

  get selectedClients() {
    return FinanceCustomerModule.filter.filterSettings.filterModel.clients.list;
  }

  getTime(minutes: string): string {
    return minutesToTime(Number(minutes));
  }

  selectAmount(value: string): void {
    FinanceCustomerModule.updatePageAmountItems(value);
  }

  openDownloadModal(): void {
    this.modalClientName = (this.selectedClients as Checkbox[])
      .filter((el: { checked: boolean }) => el.checked)
      .reduce((result: string, el: { name: string }) => (result ? `${result}, ${el.name}` : `${el.name}`), '');

    this.modalDateString = this.selectedStartDate + ' - ' + this.selectedEndDate;
    FinanceCustomerModule.showDownloadModal();
  }

  closeDownloadModal(): void {
    FinanceCustomerModule.hideDownloadModal();
  }

  startDownload(): void {
    FinanceCustomerModule.initDownload();
  }

  async updateList(): Promise<void> {
    await FinanceCustomerModule.setPageToBegin();
    await FinanceCustomerModule.filter.updateFilter();
    await FinanceCustomerModule.getList();
  }

  mounted(): void {
    FinanceModule.initTabsItems();

    FinanceCustomerModule.init();
  }
}
