import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = { class: "cp-payments-header cp-payments-header_justify cp-margin__t--small" }
const _hoisted_3 = { class: "cp-payments-header__part" }
const _hoisted_4 = { class: "cp-payments-header__part cp-flex--align-center" }
const _hoisted_5 = {
  key: 0,
  class: "app-table-row app-table-row--total"
}
const _hoisted_6 = ["colspan"]
const _hoisted_7 = { style: {"text-align":"right"} }
const _hoisted_8 = { style: {"text-align":"right"} }
const _hoisted_9 = { style: {"text-align":"right"} }
const _hoisted_10 = { style: {"text-align":"right"} }
const _hoisted_11 = { class: "cp-flex align-items-center" }
const _hoisted_12 = {
  key: 0,
  class: "cp-badge cp-badge_test cp-margin__l--3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_filter_instant = _resolveComponent("filter-instant")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_time_formatter = _resolveComponent("time-formatter")!
  const _component_text_money = _resolveComponent("text-money")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_download_modal_content = _resolveComponent("download-modal-content")!
  const _component_modal_block = _resolveComponent("modal-block")!
  const _component_app_row = _resolveComponent("app-row")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper cp-payments-wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_filter_instant, {
                filters: _ctx.filterSettings.filterModel,
                onUpdateList: _ctx.updateList
              }, null, 8, ["filters", "onUpdateList"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_icon_font, {
                size: "20",
                icon: "circle-download",
                class: "cp-color-secondary",
                onClick: _ctx.openDownloadModal
              }, null, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      }),
      (!_ctx.dataLoaded)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "cp-margin__t--small grow-1",
            headers: _ctx.settings.table.titles,
            items: _ctx.settings.table.rows,
            page: _ctx.settings.table.pagination?.currentPage,
            "total-pages": _ctx.settings.table.pagination?.totalPages,
            "total-items": _ctx.settings.table.totalItems,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            onChangePage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount
          }, {
            [`body.prepend`]: _withCtx(() => [
              (_ctx.settings.table.titles.length)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
                    _createElementVNode("td", {
                      colspan: _ctx.settings.table.titles.filter((el) => el.visible).length - 4
                    }, "ИТОГО по выбранным фильтрам", 8, _hoisted_6),
                    _createElementVNode("td", _hoisted_7, [
                      _createVNode(_component_time_formatter, {
                        value: _ctx.settings.table.totalData.total_hours
                      }, null, 8, ["value"])
                    ]),
                    _createElementVNode("td", _hoisted_8, [
                      _createVNode(_component_time_formatter, {
                        value: _ctx.settings.table.totalData.total_checkup
                      }, null, 8, ["value"])
                    ]),
                    _createElementVNode("td", _hoisted_9, [
                      _createVNode(_component_text_money, {
                        value: _ctx.settings.table.totalData.total_payment
                      }, null, 8, ["value"])
                    ]),
                    _createElementVNode("td", _hoisted_10, [
                      _createVNode(_component_text_money, {
                        value: _ctx.settings.table.totalData.employee_payment
                      }, null, 8, ["value"])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            [`item-client_name`]: _withCtx(({ value, item }) => [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_router_link, {
                  class: "app-table-link",
                  to: { name: 'customer_edit', params: { customerId: item.client_id } },
                  target: "_blank"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(value), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]),
                (item.isTestClient)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, "Test"))
                  : _createCommentVNode("", true)
              ])
            ]),
            [`item-total_hours`]: _withCtx(({ value }) => [
              _createVNode(_component_time_formatter, { value: value }, null, 8, ["value"])
            ]),
            [`item-total_checkup`]: _withCtx(({ value }) => [
              _createVNode(_component_time_formatter, { value: value }, null, 8, ["value"])
            ]),
            [`item-total_payment`]: _withCtx(({ value }) => [
              _createVNode(_component_text_money, { value: value }, null, 8, ["value"])
            ]),
            [`item-employee_payment`]: _withCtx(({ value }) => [
              _createVNode(_component_text_money, { value: value }, null, 8, ["value"])
            ]),
            _: 2
          }, 1032, ["headers", "items", "page", "total-pages", "total-items", "page-items", "onSelectAmount"])),
      (_ctx.downloadModalParams.isShow)
        ? (_openBlock(), _createBlock(_component_modal_block, {
            key: 2,
            class: "cp-modal__sm",
            title: _ctx.downloadModalParams.title,
            onClick: _ctx.closeDownloadModal
          }, {
            default: _withCtx(() => [
              _createVNode(_component_download_modal_content, {
                "client-name": _ctx.modalClientName,
                "date-string": _ctx.modalDateString,
                onDownloadClicked: _ctx.startDownload
              }, null, 8, ["client-name", "date-string", "onDownloadClicked"])
            ]),
            _: 1
          }, 8, ["title", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}